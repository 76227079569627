<template>
  <section class="section section-skew black-gradient-header">
    <div id="separator-gift" class="mt-5"></div>
    <h2 class="text-center text-white text-2xl font-weight-900 mb-3">
      Give the gift that keeps giving - Knowledge! <br />
      The perfect gift for Martial Artists
    </h2>
    <div
      data-site-id="653c47d2-8f24-4345-8ae9-1306b7f5c8ee"
      data-platform="Other"
      class="gift-up-target"
    ></div>
  </section>
</template>
<script type="text/javascript">
(function(g, i, f, t, u, p, s) {
  g[u] =
    g[u] ||
    function() {
      (g[u].q = g[u].q || []).push(arguments);
    };
  p = i.createElement(f);
  p.async = 1;
  p.src = t;
  s = i.getElementsByTagName(f)[0];
  s.parentNode.insertBefore(p, s);
})(
  window,
  document,
  "script",
  "https://cdn.giftup.app/dist/gift-up.js",
  "giftup"
);
</script>
